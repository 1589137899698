<template>
  <div>
    <div class="relative bg-teal-200 h-28 w-full md:h-16">
    </div>
    <div class="absolute top-28 z-30 w-full md:top-16">
      <div class="rounded-lg border-4 border-indigo-600 md:w-96">
        <h1 class="font-bold bg-orange-600 text-slate-300 text-3xl text-center py-2 md:px-8 md:text-left">目標を達成する標的を設定
        </h1>
        <div class="text-center py-3 px-0 bg-green-600 md:px-10 md:text-left">
          <ul class="underline font-medium text-black-300 text-2xl tracking-wide list-disclist-inside">
            <li>１年間の目標を作成</li>
            <li>３ヶ月毎の標的を作成</li>
            <li>今月の標的を作成</li>
            <li>毎日の日記を記す</li>
            <li>仲間に連絡し合う</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="absolute top-96 z-10 sm:top-80 md:top-16">
      <img :src="require('../img/achieve.jpeg')" alt="Logo" class="object-cover">
    </div>
    <div class="absolute top-80 h-28 w-full bg-teal-100">
    </div>
    <div class="absolute bottom-0 h-80 w-full bg-teal-100">
    </div>
  </div>
</template>

<script>

</script>

<style>
</style>